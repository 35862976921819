@use '../variables/colors' as *;

footer {
  background: $c-slate;
  color: $c-white;
}

footer .footer--content {
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .footer--content .social {
  display: flex;
  margin: 0 0 0 16px;
}

footer .footer--content .social img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}
