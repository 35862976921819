img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.media--type-image {
  width: 100%;
  height: 100%;
}

.no-margin {
  margin: 0;
}
