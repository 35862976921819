body {
  font-family: 'Raleway', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Fredoka One', cursive;
}

a {
  color: #0b4f6c;
}
