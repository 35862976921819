.layout-container {
  max-width: 1920px;
  margin: 0 auto;
}

.columns {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .columns {
    flex-direction: row;
  }

  .columns > .column {
    width: 50%;
  }
}
