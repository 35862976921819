$c-white: #fff;
$c-slate: #3d6073;
$c-green-pale: rgba(123, 224, 173, 0.5);
$c-black: #1a1a1a;
$c-gray-light: #b8c6db;
$c-gray-dark: #383838;
$c-maroon: #734;
$c-orange: #f0a202;
$c-wood: #b38674;
$c-grain: #a67967;
$c-grain-2: #b98b79;
