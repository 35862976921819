@use '../variables/colors' as *;
/* Scraped from https://codepen.io/robrehrig/pen/gFDls
   But cleaned up a bit */

   .turntable {
    display: none;
  }
  
  @media screen and (min-width: 768px) {
    .turntable {
      display: block;
      position: relative;
      margin: 80px 0 0;
      width: 660px;
      height: 350px;
    }
  }
  
  .turntable .disk {
    position: absolute;
    top: -60px;
    left: 128px;
    width: 300px;
    height: 300px;
    background: $c-black;
    border-radius: 150px;
    box-shadow: 0 8px $c-gray-light;
    transform: rotateX(50deg) rotateY(-3deg);
    z-index: 5;
  }
  
  .turntable .disk::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 300px;
    height: 300px;
    background: $c-gray-dark;
    border-radius: 150px;
    box-shadow: 0 3px $c-black;
  }
  
  .turntable .label {
    content: '';
    position: absolute;
    top: 75px;
    left: 95px;
    width: 110px;
    height: 110px;
    border-radius: 150px;
    background: $c-maroon;
    animation: spin 5s linear infinite;
  }
  
  .turntable .label::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 90px;
    height: 45px;
    background: $c-maroon;
    border-radius: 90px 90px 0 0;
  }
  
  .turntable .label::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100px;
    height: 100px;
    background: $c-orange;
    border-radius: 90px;
  }
  
  .turntable .axis-shadow {
    position: absolute;
    top: 75px;
    left: 274px;
    width: 6px;
    height: 10px;
    background: $c-black;
    opacity: 0.5;
    border-radius: 0 0 6px 6px;
    z-index: 6;
  }
  
  .turntable .axis {
    position: absolute;
    top: 69px;
    left: 274px;
    width: 6px;
    height: 10px;
    background: $c-white;
    opacity: 1;
    border-radius: 6px;
    z-index: 7;
  }
  
  .turntable .axis::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 4px;
    height: 8px;
    background: $c-black;
    opacity: 0.2;
    border-radius: 6px 6px 0 0;
  }
  
  .turntable .lever {
    position: absolute;
    top: 88px;
    left: 528px;
    width: 2px;
    height: 40px;
    background: $c-gray-dark;
    border-right: 1px solid $c-black;
    z-index: 5;
  }
  
  .turntable .lever::before {
    content: '';
    position: absolute;
    top: 35px;
    left: -2px;
    width: 7px;
    height: 12px;
    background: $c-black;
    border-radius: 2px;
    box-shadow: 0 6px $c-gray-light;
  }
  
  .turntable .lever::after {
    content: '';
    position: absolute;
    top: 35px;
    left: -2px;
    width: 5px;
    height: 9px;
    background: $c-gray-dark;
    border-radius: 2px 2px 0 0;
  }
  
  .turntable .table {
    position: absolute;
    top: 8px;
    left: 109px;
    width: 437px;
    height: 223px;
    background: $c-gray-dark;
    border-radius: 10px;
    transform: rotateZ(10deg) skewX(-25deg);
    z-index: 2;
  }
  
  .turntable .table::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -2px;
    width: 436px;
    height: 225px;
    background: $c-gray-light;
    border-radius: 10px;
  }
  
  .turntable .table::after {
    content: '';
    position: absolute;
    top: -12px;
    left: -6px;
    width: 436px;
    height: 225px;
    background: $c-white;
    border-radius: 10px;
  }
  
  .turntable .button {
    position: absolute;
    top: 155px;
    left: 64px;
    width: 10px;
    height: 10px;
    background: $c-gray-dark;
    border-radius: 10px;
    transform: rotateZ(30deg) skewX(-25deg);
    box-shadow: 2px 2px $c-black;
    z-index: 10;
  }
  
  .turntable .button:hover {
    cursor: pointer;
  }
  
  .turntable .wood {
    position: absolute;
    top: 219px;
    left: 43px;
    width: 440px;
    height: 26px;
    background: $c-wood;
    border-radius: 0 0 15px 8px;
    transform: rotateZ(10deg) skewX(10deg);
    overflow: hidden;
    z-index: 1;
  }
  
  .turntable .wood2 {
    position: absolute;
    top: 52px;
    left: 524px;
    width: 25px;
    height: 240px;
    background: $c-wood;
    border-radius: 0 2px 25px 0;
    transform: rotateZ(50deg) skewX(15deg) skewY(48deg);
    overflow: hidden;
  }
  
  .turntable .arm {
    position: absolute;
    top: 108px;
    left: 400px;
    width: 150px;
    height: 8px;
    background: $c-gray-dark;
    border-bottom: 3px solid $c-black;
    border-radius: 45px;
    transform: rotateZ(-55deg);
    -ms-transform: rotateZ(-55deg);
    -moz-transform: rotateZ(-55deg);
    -webkit-transform: rotateZ(-55deg);
    z-index: 4;
  }
  
  .base {
    position: absolute;
    top: 55px;
    left: 490px;
    width: 58px;
    height: 58px;
    background: $c-black;
    border-radius: 55px;
    transform: rotateZ(30deg) skewX(-26deg);
    -ms-transform: rotateZ(30deg) skewX(-26deg);
    -moz-transform: rotateZ(30deg) skewX(-26deg);
    -webkit-transform: rotateZ(30deg) skewX(-26deg);
    z-index: 4;
  }
  
  .base::before {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 58px;
    height: 58px;
    background: $c-gray-dark;
    border-radius: 55px;
    content: '';
  }
  
  .base::after {
    position: absolute;
    top: 26px;
    left: 38px;
    width: 7px;
    height: 7px;
    background: $c-gray-dark;
    border-radius: 55px;
    box-shadow: 1px 1px $c-black;
    content: '';
  }
  
  .turntable .axle-shadow {
    position: absolute;
    top: 12px;
    left: 8px;
    width: 35px;
    height: 21px;
    background: $c-black;
    transform: rotateZ(-23deg);
  }
  
  .turntable .axle-shadow::before {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 30px;
    background: $c-black;
    content: '';
  }
  
  .turntable .axle-shadow::after {
    content: '';
    position: absolute;
    top: -16px;
    left: 12px;
    width: 10px;
    height: 30px;
    background: $c-black;
  }
  
  .turntable .axle {
    position: absolute;
    top: 24px;
    left: 491px;
    width: 60px;
    height: 45px;
    background: $c-gray-dark;
    border-radius: 45px;
    transform: rotateZ(-55deg) scaleX(0.9);
    z-index: 4;
  }
  
  .turntable .axle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    background: $c-black;
    border-radius: 45px;
  }
  
  .turntable .axle::after {
    content: '';
    position: absolute;
    top: 14px;
    left: 10px;
    width: 17px;
    height: 17px;
    background: transparent;
    border-radius: 45px;
    box-shadow: 3px 0 $c-gray-dark;
  }
  
  .turntable .weight {
    position: absolute;
    top: 9px;
    left: 516px;
    width: 42px;
    height: 30px;
    background: $c-gray-dark;
    border-radius: 45px;
    transform: rotateZ(-55deg) scaleX(0.9);
    z-index: 4;
  }
  
  .turntable .weight::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background: $c-black;
    border-radius: 45px;
  }
  
  .turntable .weight::after {
    content: '';
    position: absolute;
    top: 3px;
    left: -1px;
    width: 24px;
    height: 24px;
    background: transparent;
    border-radius: 45px;
    box-shadow: 3px 0 $c-gray-dark;
  }
  
  .turntable .head {
    position: absolute;
    top: 161px;
    left: 424px;
    width: 20px;
    height: 30px;
    background: $c-black;
    border-radius: 5px 10px 0 0;
    transform: rotateZ(10deg) skewX(-25deg);
    z-index: 8;
  }
  
  .turntable .head::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 19px;
    height: 30px;
    background: $c-gray-dark;
    border-radius: 5px 10px 0 0;
  }
  
  .turntable .head::after {
    content: '';
    position: absolute;
    top: 15px;
    left: 17px;
    width: 23px;
    height: 5px;
    background: $c-gray-dark;
    box-shadow: 2px 1px $c-black;
  }
  
  .turntable .arm-shadow {
    position: absolute;
    top: 150px;
    left: 399px;
    width: 150px;
    height: 9px;
    background: $c-gray-light;
    transform: rotateZ(-55deg);
    z-index: 3;
  }
  
  .turntable .arm-shadow::before {
    content: '';
    position: absolute;
    top: -9px;
    left: 0;
    width: 19px;
    height: 28px;
    background: $c-gray-light;
    border-radius: 5px 10px 0 0;
    transform: rotateZ(65deg) skewX(-25deg);
  }
  
  .turntable .arm-shadow::after {
    content: '';
    position: absolute;
    top: 15px;
    left: -4px;
    width: 30px;
    height: 5px;
    background: $c-gray-light;
    transform: rotateZ(65deg) skewX(-25deg);
  }
  
  .turntable .weight-shadow {
    position: absolute;
    top: 43px;
    left: 535px;
    width: 20px;
    height: 25px;
    background: $c-gray-light;
    transform: rotateZ(10deg) skewX(-25deg);
    z-index: 3;
  }
  
  .turntable .weight-shadow::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 24px;
    height: 13px;
    background: $c-gray-light;
  }
  
  .turntable .grain1 {
    position: absolute;
    top: -16px;
    left: 8px;
    width: 30px;
    height: 60px;
    border: 0 solid transparent;
    border-left: 12px solid $c-grain;
    border-radius: 30px 0 0 20px;
    transform: rotate(120deg);
  }
  
  .turntable .grain1::before {
    content: '';
    position: absolute;
    top: 25px;
    left: 10px;
    width: 40px;
    height: 40px;
    border-top: 5px solid $c-wood;
    border-radius: 40px;
    transform: rotate(-60deg);
  }
  
  .turntable .grain1::after {
    content: '';
    position: absolute;
    top: -3px;
    left: -15px;
    width: 40px;
    height: 40px;
    border-top: 3px solid $c-grain;
    border-radius: 40px;
    transform: rotate(-40deg);
  }
  
  .turntable .grain2 {
    position: absolute;
    top: 0;
    left: 35px;
    width: 50px;
    height: 50px;
    border-left: 5px solid $c-grain-2;
    border-radius: 50px;
    transform: rotate(150deg);
  }
  
  .turntable .grain2::before {
    content: '';
    position: absolute;
    top: 0;
    left: -13px;
    width: 50px;
    height: 50px;
    border-left: 5px solid $c-grain-2;
    border-radius: 50px;
  }
  
  .turntable .grain2::after {
    content: '';
    position: absolute;
    top: -10px;
    left: -23px;
    width: 70px;
    height: 70px;
    border-left: 5px solid $c-grain;
    border-top: 7px solid $c-grain;
    border-radius: 50px;
    transform: rotate(-20deg);
  }
  
  .turntable .grain3 {
    position: absolute;
    top: 0;
    left: 100px;
    width: 90px;
    height: 50px;
    border-right: 8px solid $c-grain-2;
    border-top: 6px solid $c-grain-2;
    border-radius: 50px;
    transform: rotate(25deg);
  }
  
  .turntable .grain3::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 40px;
    width: 50px;
    height: 50px;
    border-top: 5px solid $c-grain;
    border-right: 5px solid $c-grain;
    border-radius: 50px;
    transform: rotate(25deg);
  }
  
  .turntable .grain3::after {
    content: '';
    position: absolute;
    top: -30px;
    left: 45px;
    width: 50px;
    height: 60px;
    border-top: 2px solid $c-grain;
    border-right: 9px solid $c-grain;
    border-radius: 50px;
    transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    -moz-transform: rotate(18deg);
    -webkit-transform: rotate(18deg);
  }
  
  .turntable .grain4 {
    position: absolute;
    top: 15px;
    left: 200px;
    width: 110px;
    height: 110px;
    border: 5px solid $c-grain;
    border-radius: 100px;
    transform: rotate(25deg);
  }
  
  .turntable .grain4::before {
    content: '';
    position: absolute;
    top: -25px;
    left: -30px;
    width: 140px;
    height: 140px;
    border-top: 7px solid $c-grain-2;
    border-radius: 100px;
    transform: rotate(25deg) rotateZ(-10deg) skewX(-10deg);
  }
  
  .turntable .grain4::after {
    content: '';
    position: absolute;
    top: -35px;
    left: -20px;
    width: 140px;
    height: 140px;
    border-top: 5px solid $c-grain;
    border-radius: 100px;
    transform: rotate(20deg) rotateZ(-10deg) skewX(0deg);
  }
  
  .turntable .grain5 {
    position: absolute;
    top: -5px;
    left: 270px;
    width: 60px;
    height: 40px;
    border-top: 6px solid $c-grain;
    border-right: 6px solid $c-grain;
    border-radius: 12px;
    transform: rotate(25deg);
  }
  
  .turntable .grain5::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 30px;
    width: 80px;
    height: 40px;
    border-top: 4px solid $c-grain;
    border-right: 6px solid $c-grain;
    border-radius: 12px;
    transform: rotate(-13deg) skewX(-25deg);
  }
  
  .turntable .grain5::after {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    width: 100px;
    height: 40px;
    border-top: 8px solid $c-grain-2;
    border-right: 5px solid $c-grain-2;
    border-radius: 20px;
    transform: rotate(-5deg);
  }
  
  .turntable .grain6 {
    position: absolute;
    top: 10px;
    left: 395px;
    width: 20px;
    height: 40px;
    border-top: 6px solid $c-grain;
    border-right: 7px solid $c-grain;
    border-radius: 0 20px 20px 0;
    transform: rotate(25deg);
  }
  
  .turntable .grain6::before {
    content: '';
    position: absolute;
    top: -35px;
    left: -28px;
    width: 30px;
    height: 30px;
    border-bottom: 6px solid $c-grain;
    border-radius: 0 30px;
  }
  
  .turntable .grain7 {
    position: absolute;
    top: 25px;
    left: 2px;
    width: 40px;
    height: 40px;
    border-top: 8px solid $c-grain;
    border-radius: 20px 20px 20px 0;
    transform: skewY(-50deg);
  }
  
  .turntable .grain7::before {
    position: absolute;
    top: -15px;
    left: 0;
    width: 40px;
    height: 40px;
    border-top: 4px solid $c-grain;
    border-radius: 20px 20px 20px 0;
    content: '';
  }
  
  .turntable .grain7::after {
    content: '';
    position: absolute;
    top: -29px;
    left: 2px;
    width: 40px;
    height: 40px;
    border-top: 6px solid $c-grain-2;
    border-radius: 20px 20px 20px 0;
  }
  
  .turntable .grain8 {
    position: absolute;
    top: 35px;
    left: -30px;
    width: 120px;
    height: 120px;
    border-top: 4px solid $c-grain;
    border-radius: 100px;
    transform: skewY(-58deg) skewX(-15deg);
  }
  
  .turntable .grain8::before {
    content: '';
    position: absolute;
    top: 2px;
    left: -7px;
    width: 150px;
    height: 150px;
    border-top: 6px solid $c-grain;
    border-radius: 100px;
  }
  
  .turntable .grain9 {
    position: absolute;
    top: 88px;
    left: -8px;
    width: 40px;
    height: 40px;
    border-top: 5px solid $c-grain;
    border-right: 6px solid $c-grain;
    border-radius: 40px 40px 0 40px;
    transform: skewY(-50deg) skewX(-15deg) scaleX(0.7) rotateZ(-40deg);
  }
  
  .turntable .grain9::before {
    content: '';
    position: absolute;
    top: 5px;
    left: -8px;
    width: 40px;
    height: 50px;
    border-top: 5px solid $c-grain-2;
    border-radius: 40px 40px 0 40px;
    transform: rotateZ(40deg) scaleX(1.2);
  }
  
  .turntable .grain10 {
    position: absolute;
    top: 132px;
    left: 3px;
    width: 40px;
    height: 40px;
    border-top: 7px solid $c-grain;
    border-left: 7px solid $c-grain;
    border-radius: 15px 0 0 0;
    transform: skewY(-48deg) skewX(-15deg) rotateZ(25deg);
  }
  
  .turntable .grain10::before {
    content: '';
    position: absolute;
    top: -27px;
    left: -14px;
    width: 40px;
    height: 40px;
    border-top: 3px solid $c-grain;
    border-left: 3px solid $c-grain;
    border-radius: 25px 0 0 0;
    transform: rotateZ(3deg);
  }
  
  .turntable .grain11 {
    position: absolute;
    top: 175px;
    left: -3px;
    width: 40px;
    height: 40px;
    border-top: 5px solid $c-grain-2;
    border-left: 7px solid $c-grain-2;
    border-radius: 15px 0 0 0;
    transform: skewY(-48deg) skewX(-10deg) rotateZ(30deg);
  }
  
  .turntable .grain11::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 5px;
    width: 40px;
    height: 40px;
    border-top: 3px solid $c-grain;
    border-left: 3px solid $c-grain;
    border-radius: 10px 0 0 0;
    transform: skewX(10deg) rotateZ(-8deg);
  }
  
  .turntable .grain11::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 10px;
    width: 40px;
    height: 40px;
    border-top: 3px solid $c-grain;
    border-left: 3px solid $c-grain;
    border-radius: 20px 0 0 0;
    transform: skewX(10deg) rotateZ(-10deg);
  }
  
  /* keyframes for wheel animation;  simple 0 to 360 */
  @keyframes spin {
    from {
      transform: rotateZ(0deg);
    }
  
    to {
      transform: rotateZ(360deg);
    }
  }
  