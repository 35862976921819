@use '../variables/colors' as *;

.intro .splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  background: center / cover no-repeat url('mountains.svg');
}

.intro .splash h2 {
  font-size: 6vw;
  margin: 0;
  letter-spacing: 2px;
}

@media screen and (min-width: 768px) {
  .intro .splash h2 {
    letter-spacing: 4px;
  }
}

.intro .splash .credit {
  padding-top: 60px;
  font-style: italic;
  font-size: 12px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .intro .splash .credit {
    padding-top: 20px;
  }
}

.intro .splash .credit a {
  display: block;
  margin: 0 0 10px;
  color: $c-white;
}

.intro .splash .credit .turntable-pen {
  display: none;
}

@media screen and (min-width: 768px) {
  .intro .splash .credit .turntable-pen {
    display: block;
  }
}

.intro .intro--txt,
.intro .experience--txt {
  display: flex;
  align-items: center;
}

.intro .intro--txt {
  background: $c-slate;
  color: $c-white;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: 1px;
}

@media screen and (min-width: 768px) {
  .intro .intro--txt {
    font-size: 2.5vw;
  }
}

.intro .intro--txt p {
  padding: 30px 60px;
}

@media screen and (min-width: 768px) {
  .intro .intro--txt p {
    padding: 0 60px;
  }
}

.intro .experience--txt {
  background: rgba(123, 224, 173, 0.5);
  flex-direction: column;
  padding: 60px 0;
  font-size: 18px;
}

.intro .experience--txt .experience--intro {
  padding: 0 60px;
  line-height: 1.4;
}

.intro .experience--txt ul {
  padding: 0 80px;
}

.intro .experience--txt ul li {
  margin: 0 0 36px;
}

.intro .experience div:first-child {
  order: 2;
}

@media screen and (min-width: 768px) {
  .intro .experience div:first-child {
    order: -1;
  }
}

.contact {
  display: flex;
}

.contact iframe {
  width: 100%;
  height: 800px;
  padding: 60px 0 0;
  background: $c-green-pale;
}
